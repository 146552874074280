// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-thanks-jsx": () => import("./../../../src/pages/thanks.jsx" /* webpackChunkName: "component---src-pages-thanks-jsx" */),
  "component---src-templates-archive-jsx": () => import("./../../../src/templates/archive.jsx" /* webpackChunkName: "component---src-templates-archive-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-branding-jsx": () => import("./../../../src/templates/branding.jsx" /* webpackChunkName: "component---src-templates-branding-jsx" */),
  "component---src-templates-code-archive-jsx": () => import("./../../../src/templates/codeArchive.jsx" /* webpackChunkName: "component---src-templates-code-archive-jsx" */),
  "component---src-templates-code-jsx": () => import("./../../../src/templates/code.jsx" /* webpackChunkName: "component---src-templates-code-jsx" */),
  "component---src-templates-code-personal-jsx": () => import("./../../../src/templates/codePersonal.jsx" /* webpackChunkName: "component---src-templates-code-personal-jsx" */),
  "component---src-templates-code-work-jsx": () => import("./../../../src/templates/codeWork.jsx" /* webpackChunkName: "component---src-templates-code-work-jsx" */),
  "component---src-templates-design-archive-jsx": () => import("./../../../src/templates/designArchive.jsx" /* webpackChunkName: "component---src-templates-design-archive-jsx" */),
  "component---src-templates-design-jsx": () => import("./../../../src/templates/design.jsx" /* webpackChunkName: "component---src-templates-design-jsx" */),
  "component---src-templates-guide-jsx": () => import("./../../../src/templates/guide.jsx" /* webpackChunkName: "component---src-templates-guide-jsx" */),
  "component---src-templates-illustration-jsx": () => import("./../../../src/templates/illustration.jsx" /* webpackChunkName: "component---src-templates-illustration-jsx" */),
  "component---src-templates-opinion-jsx": () => import("./../../../src/templates/opinion.jsx" /* webpackChunkName: "component---src-templates-opinion-jsx" */),
  "component---src-templates-print-jsx": () => import("./../../../src/templates/print.jsx" /* webpackChunkName: "component---src-templates-print-jsx" */),
  "component---src-templates-tech-jsx": () => import("./../../../src/templates/tech.jsx" /* webpackChunkName: "component---src-templates-tech-jsx" */),
  "component---src-templates-travel-jsx": () => import("./../../../src/templates/travel.jsx" /* webpackChunkName: "component---src-templates-travel-jsx" */),
  "component---src-templates-ux-ui-jsx": () => import("./../../../src/templates/ux-ui.jsx" /* webpackChunkName: "component---src-templates-ux-ui-jsx" */)
}

